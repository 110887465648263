import React, { useEffect, useState } from "react";
import Imgix from "react-imgix";
import cx from "classnames";
import { useSelector } from "react-redux";
import { format } from "date-fns-tz";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { navigate } from "gatsby-link";
import styles from "./AuctionSteps.module.scss";
import {
  getAuction,
  getAuctionModalIsEditing,
} from "../../../../../../store/auctionModal/selectors";
import { Auction } from "../../../../../../types/Auction";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
} from "../../../../../../store/user/selectors";
import { Market } from "../../../../../../types/Market";
import { useAppDispatch } from "../../../../../../store";
import { isLoading } from "../../../../../../store/auctions/selectors";
import LoadingSmall from "../../../../../Loading/LoadingSmall";
import { User } from "../../../../../../types/User";
import AuctionButtonsContainer from "../internal/AuctionButtonsContainer";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../../../../../store/user/actions";
import BasicTable from "../../../../../Table/BasicTable";
import BasicTableHeader from "../../../../../Table/BasicTableHeader";
import BasicTableRow from "../../../../../Table/BasicTableRow";
import BasicTableBody from "../../../../../Table/BasicTableBody";
import {
  getCurrencyLocaleValue,
  isMobile,
} from "../../../../../../utils/helpers";

const AuctionPreview = () => {
  const dispatch = useAppDispatch();

  const [localLoading, setLocalLoading] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState("");

  const loading = useSelector((state) => isLoading(state)) as boolean;
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const isEditing = useSelector((state) =>
    getAuctionModalIsEditing(state)
  ) as boolean;

  useEffect(() => {
    setLocalLoading(true);
    dispatch(getUserInstagramAccounts());
  }, []);

  useEffect(() => {
    setLocalLoading(false);
  }, [availableMarkets]);

  useEffect(() => {
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
  }, []);

  useEffect(() => {
    if (availableMarkets.length > 0) {
      const selectedAuctionMarket = availableMarkets.find(
        (market) => market.id === auction.market_id
      );
      if (selectedAuctionMarket) {
        setSelectedMarket(selectedAuctionMarket.name);
      }
    }
  }, [availableMarkets, auction]);

  const getCombinedCopy = () => {
    if (!auction || !auction.starts_at || !auction.ends_at) {
      return "";
    }
    const formattedStartDate = format(
      new Date(auction.starts_at),
      "MMM, dd, yyyy h:mm aa zzz"
    );
    const formattedEndDate = format(
      new Date(auction.ends_at),
      "MMM, dd, yyyy h:mm aa zzz"
    );
    const info = auction.description.info
      ? `<p>${auction.description.info.replace(/\n/g, "<br/>")}</p>`
      : "";
    const auctionDescription = `
      <p>${auction.description.common}</p><br/>
      <p>Start Date: ${formattedStartDate}</p>
      <p>Ends At: ${formattedEndDate}</p>
      <p>Starting Bid Price: 
      ${getCurrencyLocaleValue(auction.currency, auction.item.price)}
      </p>
      <p>Bid Increment: 
      ${getCurrencyLocaleValue(auction.currency, auction.item.increment)}
      </p>
      <p>Currency: ${auction.currency}</p><br/>
      ${info}
    `;

    return auctionDescription;
  };

  const nextPage = () => {
    if (isEditing) {
      navigate(`/dashboard/auctions/${auction.id}/post-auction/`);
    } else {
      navigate("/dashboard/auctions/create/post-auction/");
    }
  };

  const navigateToPage = (path) => {
    navigate(path);
  };

  return (
    <div className={styles.creationContainer}>
      {auction && auction.type !== "multi" && auction.item.files.length > 0 && (
        <div className={cx([styles.creationImageContainer, "mb-4"])}>
          <div className={styles.mobileOnly}>
            <h3>@{selectedMarket}</h3>
            <h2 className="mb-4">
              Preview Your
              <br /> Auction
            </h2>
          </div>
          <div className="relative">
            <Imgix
              src={`${auction.item.files[0].url}?auto=format`}
              width={570}
              height={570}
              htmlAttributes={{
                alt: "Uploaded Image",
                src: `${auction.item.files[0].imgix_url}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
            <button
              type="button"
              className={cx([styles.previewEditButton, styles.editImage])}
              onClick={() =>
                navigateToPage("/dashboard/auctions/create/add-image/")
              }
            >
              Edit
            </button>
          </div>
        </div>
      )}
      <div className={styles.textContainer}>
        <div className={styles.desktopOnly}>
          <h3>@{selectedMarket}</h3>
          <h2>
            Preview Your
            <br /> Auction
          </h2>
          <p>
            Please preview your auction and click edit to make changes to images
            or details.
          </p>
        </div>
        <div className="mb-4">
          <BasicTable>
            <BasicTableHeader>
              {!isMobile.any() && (
                <BasicTableRow>
                  <td colSpan={2} className={styles.desktopOnly}>
                    Auction Name
                  </td>
                </BasicTableRow>
              )}
            </BasicTableHeader>
            <BasicTableBody>
              <BasicTableRow hideBorderBottom>
                <td className={styles.tableRowCopy}>
                  <p className={styles.auctionName}>{auction.name}</p>
                </td>
                <td className={styles.tableRowButton}>
                  <button
                    type="button"
                    className={styles.previewEditButton}
                    onClick={() =>
                      navigateToPage("/dashboard/auctions/create/add-title/")
                    }
                  >
                    Edit
                  </button>
                </td>
              </BasicTableRow>
            </BasicTableBody>
          </BasicTable>
        </div>
        <BasicTable>
          <BasicTableHeader>
            <BasicTableRow>
              <td className={styles.desktopOnly} colSpan={2}>
                Auction Details
              </td>
            </BasicTableRow>
          </BasicTableHeader>
          <BasicTableBody>
            <BasicTableRow hideBorderBottom>
              <td className={styles.tableRowCopy}>
                <div
                  className={styles.noMarginCopy}
                  dangerouslySetInnerHTML={{ __html: getCombinedCopy() }}
                />
              </td>
              <td className={styles.tableRowButton}>
                <button
                  type="button"
                  className={styles.previewEditButton}
                  onClick={() =>
                    navigateToPage("/dashboard/auctions/create/add-settings/")
                  }
                >
                  Edit
                </button>
              </td>
            </BasicTableRow>
          </BasicTableBody>
        </BasicTable>
        <div className={cx([styles.learnContent, styles.noBorderTop])}>
          <AuctionButtonsContainer
            disabled={localLoading}
            nextPage={() => nextPage()}
          />
        </div>
      </div>
      {(loading || localLoading) && (
        <div className={cx(["fixed inset-0", styles.loadingContainer])}>
          <LoadingSmall />
        </div>
      )}
    </div>
  );
};

export default AuctionPreview;
